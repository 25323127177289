import React from "react";
import "./about.css";
import about1 from "../assets/aboutUs1.png";
import about2 from "../assets/aboutUs2.png";

import Footer from "../component/footer";
const about = () => {
  return (
    <div className="about">
      <img className="start" src={about1} alt="" />

      <h1 className="visionHeading">Vision</h1>
      <p className="text">Empowering Progress through Diversified Innovation</p>
      <div className="vision">
        <p className="text1" style={{ textAlign: "justify" }}>
          At <strong>Taayanam</strong>, our vision is to be the catalyst for
          transformative change across diverse sectors. We envision a future
          where technology seamlessly integrates with every facet of society,
          from revolutionizing agriculture through{" "}
          <strong>Agritech solutions</strong> to enhancing public safety with
          cutting-edge <strong>Policing Software</strong>. Our commitment
          extends beyond conventional boundaries as we strive to offer a
          spectrum of <strong>IT services</strong> that elevate businesses to
          unprecedented levels of efficiency and success. With a relentless
          pursuit of innovation, we aim to empower our clients to navigate the
          digital landscape with confidence, knowing they have a partner
          dedicated to shaping a progressive and technologically advanced
          future. In our journey, we aspire to be at the forefront of
          technological evolution, consistently pushing the boundaries of what's
          possible. We are driven by the belief that every challenge presents an
          opportunity for innovation. By fostering a culture of creativity and
          adaptability, we aim to develop solutions that not only meet current
          needs but anticipate the future demands of a dynamic and
          interconnected world. At the heart of our vision is a commitment to
          excellence and client satisfaction. We strive to be recognized as a
          trusted partner, providing tailored IT solutions that resonate with
          the unique requirements of each industry we serve. Through
          collaboration, expertise, and a passion for progress, we aim to be the
          go-to <strong>IT Solutions</strong> company, contributing to the
          success stories of our clients across the globe. Join us on this
          journey of exploration, where we turn possibilities into realities and
          pave the way for a future where technology becomes an unparalleled
          force for positive change. Together, let's shape a world where
          innovation knows no bounds, and IT solutions become the cornerstone of
          progress in every sector imaginable.
        </p>
      </div>
      <h1 className="visionHeading">Mission</h1>
      <div className="mission">
        <p className="text2" style={{ textAlign: "justify" }}>
          <strong>At Taayanam, Our Mission:</strong>
          Our mission is to <strong>
            pioneer transformative IT solutions
          </strong>{" "}
          that empower businesses and industries to thrive in the digital era.
          Committed to delivering innovative, client-centric services across
          diverse domains, from Agritech to Policing Software, we ensure our
          clients not only adapt to technological advancements but lead in
          embracing them. Guided by a <strong>passion for excellence</strong>{" "}
          and a dedication to personalized service, we strive to be the driving
          force behind our clients' success, offering a seamless blend of
          expertise, creativity, and unwavering commitment to their unique
          visions. Our mission is to be more than an IT solutions provider – we
          aim to be your strategic partner in navigating and shaping the future
          of technology. We are on a mission to foster a culture of continuous
          innovation, where{" "}
          <strong>challenges are seen as opportunities</strong> and every
          project is an opportunity to push the boundaries of what's achievable.
          Our dedicated team of experts works tirelessly to ensure that our
          clients not only meet but exceed their goals through the
          implementation of cutting-edge technologies and strategic IT
          solutions. Furthermore, our mission extends beyond technological
          advancements; we are dedicated to{" "}
          <strong>social responsibility</strong> and ethical practices. By
          prioritizing sustainability, diversity, and community engagement, we
          aim to make a positive impact on the world, aligning our mission with
          the broader goals of creating a more inclusive and sustainable digital
          future. Join us on this mission to{" "}
          <strong>redefine possibilities</strong>, shape industries, and
          contribute to a world where technology serves as a powerful force for
          positive change. Together, let's navigate the ever-evolving landscape
          of IT, making a lasting impact on businesses, communities, and the
          world at large.
        </p>
      </div>
      <br />
      <br />
      <br />
      <div className="goal">
        <h1 className="visionHeading">Goals</h1>
        <p className="text1" style={{ textAlign: "justify" }}>
          <strong>Our Overarching Goal at Taayanam:</strong>
          Our overarching goal at <strong>Taayanam</strong> is to be a
          trailblazer in the <strong>IT industry</strong>, setting new standards
          for innovation, reliability, and client satisfaction. We aim to
          achieve this by consistently delivering bespoke solutions that not
          only meet but exceed the evolving needs of our clients across diverse
          sectors. Specifically, we strive to:
          <strong>Drive Innovation:</strong> Pioneer and implement cutting-edge
          technologies to provide our clients with forward-thinking solutions
          that position them at the forefront of their industries.
          <strong>Client Empowerment:</strong> Empower our clients by tailoring
          IT solutions that enhance their operational efficiency, foster growth,
          and ensure a competitive edge in the digital landscape.
          <strong>Global Impact:</strong> Expand our reach and influence on a
          global scale, contributing to the positive evolution of industries and
          communities through impactful IT solutions and responsible business
          practices.
          <strong>Continuous Improvement:</strong> Foster a culture of
          continuous improvement within our team, ensuring that we stay agile,
          adaptive, and at the forefront of emerging technologies and industry
          trends.
          <strong>Ethical and Sustainable Practices:</strong> Champion ethical
          business practices and sustainability, aligning our operations with
          values that contribute positively to the environment, society, and the
          broader digital ecosystem. Our goal is not just to meet expectations
          but to exceed them, creating lasting value for our clients, employees,
          and the world we operate in.
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default about;
