import React from "react";

const Testimonials = () => {
  return (
    <div>
      <h1 className="sub-title">Partnership</h1>
    </div>
  );
};

export default Testimonials;
