import React from "react";
import Footer from "../component/footer";
import LawE from "../assets/Law enforcement.gif";
const Law = () => {
  return (
    <>
      <div className="body">
        <div className="agritech">
          <div>
            <img src={LawE} alt="" />
          </div>
        </div>
        <div className="agriDetails">
          <h1 className="agriHeading">
            <strong> Taayanam</strong>: Law Enforcement Solutions
          </h1>
          <p
            className="agriPara"
            style={{ textAlign: "justify", padding: "3% 10% 3% 10%" }}
          >
            At <strong>Taayanam</strong> we understand the critical role law
            enforcement plays in ensuring public safety. Our cutting-edge
            software solutions seamlessly integrate Artificial Intelligence
            (AI), Cloud Technology, Data Integration, and prioritize Data
            Security and Privacy to empower law enforcement agencies in their
            mission.
          </p>
        </div>
        <div className="containers">
          <div className="row">
            <div className="cardContain">
              <svg
                className="rounded-circle AI"
                width="140"
                height="140"
                xmlns="http://www.w3.org/2000/svg"
                role="img"
                aria-label="Placeholder"
                preserveAspectRatio="xMidYMid slice"
                focusable="false"
              >
                <title>Placeholder</title>
              </svg>
              <h2 className="fw-normal">Use of AI</h2>
              <p style={{ textAlign: "justify" }}>
                <strong> Taayanam</strong> harnesses the power of AI to enhance
                law enforcement capabilities. Predictive analytics, facial
                recognition, and automated data analysis enable agencies to make
                more informed decisions swiftly. AI-driven automation optimizes
                processes, streamlining investigations and response protocols
                for heightened efficiency.
              </p>
              <p>
                <a className="btn btn-danger" href="#">
                  View details »
                </a>
              </p>
            </div>
            <div className="cardContain">
              <svg
                className="rounded-circle cloud"
                width="140"
                height="140"
                xmlns="http://www.w3.org/2000/svg"
                role="img"
                aria-label="Placeholder"
                preserveAspectRatio="xMidYMid slice"
                focusable="false"
              >
                <title>Placeholder</title>
              </svg>
              <h2 className="fw-normal">Cloud-Powered Technology</h2>
              <p style={{ textAlign: "justify" }}>
                Our software solutions leverage Cloud Technology to
                revolutionize how law enforcement agencies manage and access
                data. Cloud infrastructure ensures secure and centralized data
                storage, promoting collaboration among agencies and facilitating
                real-time information sharing. This scalable and adaptable
                approach future-proofs your agency against evolving
                technological needs.
              </p>
              <p>
                <a className="btn btn-danger" href="#">
                  View details »
                </a>
              </p>
            </div>
            <div className="cardContain">
              <svg
                className="rounded-circle data"
                width="140"
                height="140"
                role="img"
                aria-label="Placeholder"
                preserveAspectRatio="xMidYMid slice"
                focusable="false"
              >
                <title>Placeholder</title>
              </svg>
              <h2 className="fw-normal">Ready for Data Integration</h2>
              <p style={{ textAlign: "justify" }}>
                Taayanam's commitment to Data Integration enables law
                enforcement agencies to consolidate information from various
                sources into a unified platform. This integration fosters a
                comprehensive view of cases, streamlining investigative
                processes and enhancing overall situational awareness for more
                effective law enforcement.
              </p>
              <p>
                <a className="btn btn-danger" href="#">
                  View details »
                </a>
              </p>
            </div>
          </div>
        </div>
        <hr className="featurette-divider" />
        {/* --------------------------agritech Section 1 ---------------------------------- */}
        <div className="containerSection">
          <div className="row featurette agritechSection1">
            <div className="col-md-7">
              <h2 className="featurette-heading fw-normal lh-1">
                Robust Data Security Measures:{" "}
              </h2>
              <br />

              <p
                className="lead"
                style={{ textAlign: "justify", padding: "5% 10% 0% 10%" }}
              >
                We prioritize the highest standards of data protection.
                Taayanam's software solutions implement robust security measures
                to safeguard sensitive law enforcement data. Encryption
                protocols, secure communication channels, and continuous
                monitoring ensure the integrity and confidentiality of your
                agency's information.
              </p>
            </div>
            <div className="col-md-5">
              <svg
                className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto platform1"
                width="600"
                height="400"
                role="img"
                aria-label="Placeholder: 500x500"
                preserveAspectRatio="xMidYMid slice"
                focusable="false"
              >
                <title>Placeholder</title>
              </svg>
            </div>
          </div>

          {/* -------------------------------------agritech section 2 ------------------------- */}
          <div className="row featurette agritechSection1 ">
            <div className="col-md-7 order-md-2">
              <h2 className="featurette-heading fw-normal lh-1">
                Privacy-First Approach <br />
              </h2>
              <br />
              <p
                className="lead"
                style={{ textAlign: "justify", padding: "5% 10% 0% 10%" }}
              >
                Respecting privacy is paramount in our software solutions.
                Taayanam prioritizes a privacy-first approach, ensuring that
                data is handled ethically and in compliance with privacy
                regulations. Our solutions empower law enforcement agencies to
                strike the right balance between public safety and individual
                privacy rights.
              </p>
            </div>
            <div className="col-md-5 order-md-1">
              <svg
                className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto Law4"
                width="600"
                height="400"
                role="img"
                aria-label="Placeholder: 500x500"
                preserveAspectRatio="xMidYMid slice"
                focusable="false"
              >
                <title>Placeholder</title>
              </svg>
            </div>
          </div>
          {/* ---------------------------------------------------------- */}
          <div className="row featurette agritechSection1">
            <div className="col-md-7">
              <h2 className="featurette-heading fw-normal lh-1">
                Scalability and Future-Proofing <br />
              </h2>
              <br />
              <p
                className="lead"
                style={{ textAlign: "justify", padding: "5% 10% 0% 10%" }}
              >
                Taayanam builds flexibility into our software solutions,
                ensuring they remain valuable assets over the long term.
                Scalability features enable seamless growth, adapting to the
                dynamic landscape of law enforcement needs and technological
                advancements.
              </p>
            </div>
            <div className="col-md-5">
              <svg
                className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto Law5"
                width="600"
                height="400"
                role="img"
                aria-label="Placeholder: 500x500"
                preserveAspectRatio="xMidYMid slice"
                focusable="false"
              >
                <title>Placeholder</title>
              </svg>
            </div>
          </div>
        </div>
        {/* ------------------------------------------- */}
      </div>
      <Footer />
    </>
  );
};

export default Law;
